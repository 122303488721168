import Back1 from '../images/background-images/bg-image-1.png';
import Back2 from '../images/background-images/bg-image-2.png';
import Back3 from '../images/background-images/bg-image-3.png';
import Back4 from '../images/background-images/bg-image-4.png';
import Back5 from '../images/background-images/bg-image-5.png';
import Back6 from '../images/background-images/bg-image-6.png';
import Back7 from '../images/background-images/bg-image-7.jpg';

// Displayed themes are filtered here: src/pages/profileSettings/userInfoForms/setThemeComponent.js
// TODO Filter and display themes based on app-config property

export const ColorThemes = [
  {
    id: '1',
    back: Back1,
    baseColor: '',
    baseColor: '#b5b5b5',
  },
  {
    id: '2',
    back: Back2,
    baseColor: '#b5b5b5',
  },
  {
    id: '3',
    back: Back3,
    baseColor: '#b5b5b5',
  },
  {
    id: '4',
    back: Back4,
    baseColor: '#b5b5b5',
  },
  {
    id: '5',
    back: Back5,
    baseColor: '#b5b5b5',
  },
  {
    id: '6',
    back: Back6,
    baseColor: '#f26e17',
  },
  {
    id: '7',
    back: Back7,
    baseColor: '#004030',
  },
];
