import { v1 } from 'uuid';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { CodeInput } from '../../../components/common/CodeInput';
import { Button } from '../../../components/common/Button';
import { RadioButton } from '../../../components/common/RadioButton';
import { required, isAccepted, length, numbers } from '../../../helpers/formValidations';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { authActions } from '../../../redux/auth/authSlice';
import { getPhoneCode } from '../../../redux/auth/authConstants';
import { getLinksSelector } from '../../../redux/service/serviceSelectors';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { checkIsIphoneX } from '../../../helpers/checkIsIphoneX';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { isBelarus } from '../../../helpers';
import { CheckboxInput } from '../../../components/common/CheckboxInput/CheckboxInput';
import './verifyComponent.scss';

export const VerifyComponent = ({ formObj, phone, auth, handleSubmit, invalid, prevStep, resetForm }) => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  useEffect(() => resetForm, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        modalActions.openPromptModal({
          title: formatMessage("Didn't get the code?"),
          description: formatMessage(["If you didn't get sms with code, please press 'Resend'"]),
          btnText: formatMessage('Resend'),
          onButtonClick: () => {
            dispatch(authActions.sendPhone(phone.substr(getPhoneCode().length)));
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }, 20000);

    return () => clearTimeout(timer);
  }, [dispatch, phone, formatMessage]);

  const links = useSelector(getLinksSelector);
  const isIphone = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  return (
    <form onSubmit={handleSubmit} className="verify__component" style={{ overflowX: isIphone ? 'hidden' : 'auto' }}>
      <div className="verify__top-section">
        <Button
          withoutTheme
          type="button"
          className="verify__top-button"
          onClick={() => {
            resetForm();
            prevStep();
          }}
        >
          <ArrowIconRounded fillColor="#fff" className="verify__top-arrow" />
        </Button>
        <h1 className="verify__title">{formatMessage('Enter the SMS verification code')}</h1>

        <div className="verify__middle-section">
          <CodeInput
            name="code"
            customClass="verify__code"
            inputContainerStyle={'code-input__container'}
            numberOfInputs={4}
            validate={[required, length(4), numbers]}
          />
          <p className="verify__text">{formatMessage('The code has been sent to the phone')}</p>
          <div className="verify__radio-container">
            <p className="verify__text">
              {formatMessage('I agree to the')}{' '}
              <a
                href={links.general_terms_and_conditions}
                className="verify__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatMessage('General terms and conditions')}
              </a>
              {formatMessage('I agree end part gtam')}
            </p>
            {isBelarus() ? (
              <CheckboxInput name="terms" inputId={v1()} validate={[isAccepted]} />
            ) : (
              <RadioButton name="terms" validate={[isAccepted]} />
            )}
          </div>
          <div className="verify__radio-container">
            <p className="verify__text">
              {formatMessage('I agree to the(PP)')}{' '}
              <a href={links.privacy_policy} className="verify__link" target="_blank" rel="noopener noreferrer">
                {formatMessage('Privacy policy')}
              </a>
              {formatMessage('I agree end part policy')}
            </p>
            {isBelarus() ? (
              <CheckboxInput name="privacy" inputId={v1()} validate={[isAccepted]} />
            ) : (
              <RadioButton name="privacy" validate={[isAccepted]} />
            )}
          </div>
        </div>
      </div>
      <div
        className="verify__bottom-section"
        style={{ marginBottom: checkIsIphoneX() || window.screen.height < 600 ? '60px' : '30px' }}
      >
        <ArrowButton withoutTheme disabled={invalid} onClick={() => auth({ phone, smsCode: formObj.values.code })} />
      </div>
    </form>
  );
};
