import { Field } from 'redux-form';
import classnames from 'classnames';

import { useInternationalization } from '../../../hooks/useTranslationHook';

import './Select.scss';

export const CommonSelect = (props) => {
  const {
    customClass,
    options,
    id,
    placeholder,
    withTranslations,
    additional_value,
    meta: { touched, error },
    disabled,
    withObjectValueOption,
    sectionId,
  } = props;
  const { formatMessage } = useInternationalization();

  return (
    <div className={classnames('select-block', customClass)}>
      <select
        {...props.input}
        id={id}
        className={`select-block__select${touched && error ? '-invalid' : ''}`}
        disabled={disabled}
      >
        {placeholder && (
          <option value="" disabled defaultValue>
            {placeholder}
          </option>
        )}
        {additional_value && (
          <option style={{ color: 'black' }} value={null}>
            {additional_value}
          </option>
        )}
        {options.map((option) => (
          <option
            value={
              withObjectValueOption
                ? JSON.stringify({ answer: option.answer, correct: option.correct, section_id: sectionId })
                : option.id
            }
            key={`${id}-${option.id}`}
            style={{ color: 'black' }}
          >
            {withTranslations
              ? formatMessage(option.name)
              : option.name || option.answer + ` (${option.dropdown_index})`}
          </option>
        ))}
      </select>
      {touched && error && <div className="select-block__warning-message">{formatMessage(error)}</div>}{' '}
    </div>
  );
};

export const Select = (props) => {
  return <Field component={CommonSelect} {...props} />;
};
