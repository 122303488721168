import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '../../../components/common/Button';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { APP_TYPE } from '../../../const/appConstants';
import { WarningFooter } from '../warningFooter/warningFooter';
import { setStorageItem, getStorageItem } from '../../../helpers/localStorageHelper';
import { NOT_FIRST_VIEW_STORAGE_KEY } from '../../../const/localStorageKeys';

import './onboardingPage.scss';

const REGIONS_WITH_ONBOARDING = []; // Add app type to display onboarding page

const appTypeClassName = (APP_TYPE || '').toLowerCase();

export const OnboardingPage = () => {
  const [onboardingStep, setOnboardingStep] = useState(0);

  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const onNotNowCallback = useCallback(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 1,
      }),
    );
    setStorageItem(NOT_FIRST_VIEW_STORAGE_KEY, true);
  }, [dispatch]);

  useEffect(() => {
    const notFirstView = getStorageItem(NOT_FIRST_VIEW_STORAGE_KEY);
    if (notFirstView) {
      dispatch(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.authStepList,
          stepId: 1,
        }),
      );
    } else {
      if (REGIONS_WITH_ONBOARDING.some((i) => i === APP_TYPE)) return;
      else
        dispatch(
          stepListActions.setStep({
            stepListId: STEP_LIST_IDS.authStepList,
            stepId: 1,
          }),
        );
    }
  });

  const getOnboardingSteps = () => {
    switch (onboardingStep) {
      case 0:
        return (
          <>
            <div className="onboarding__top">
              <div className={`onboarding__logo ${appTypeClassName}`} />
            </div>

            <div className="onboarding__middle">
              <div className="onboarding__title">{formatMessage('Welcome to JTI CONNEX')}</div>

              <div className="onboarding__description">
                {formatMessage('Take a quick tour or skip to start registration')}
              </div>
            </div>

            <div className="onboarding__bottom">
              <Button className="onboarding__exit themed-background" onClick={onNotNowCallback}>
                {formatMessage('Not now')}{' '}
              </Button>

              <Button className="onboarding__next themed-border" onClick={() => setOnboardingStep(1)}>
                {formatMessage('Take me on a tour')}
              </Button>
            </div>
          </>
        );
      case 1:
        return (
          <div className="onboarding__step">
            <div>
              <div className="onboarding__skip" onClick={onNotNowCallback}>
                {formatMessage('SKIP')}
              </div>

              <div className={`onboarding__step-logo-1 ${appTypeClassName}`} />
              <div className="onboarding__step-count">1/3</div>
              <div className="onboarding__step-info-1">
                {formatMessage(
                  'This application is for professional tobacco retailers only. Please use it only on your phone and don’t show it to consumers. If you are not longer working as a shop assistant, please delete your account and the application itself.',
                )}
              </div>
            </div>

            <div className="onboarding__step__bottom">
              <ArrowButton customClass="onboarding__next-step themed-svg-fill" onClick={() => setOnboardingStep(2)} />
            </div>
            <WarningFooter />
          </div>
        );
      case 2:
        return (
          <div className="onboarding__step">
            <div>
              <div className="onboarding__skip" onClick={onNotNowCallback}>
                {formatMessage('SKIP')}
              </div>

              <div className={`onboarding__step-logo-2 ${appTypeClassName}`} />
              <div className="onboarding__step-count">2/3</div>
              <div className="onboarding__step-info-2">
                {formatMessage('Join our fun and competitive gaming app for a chance to win big!')}
              </div>
            </div>

            <div className="onboarding__step__bottom">
              <ArrowButton customClass="onboarding__next-step themed-svg-fill" onClick={() => setOnboardingStep(3)} />
            </div>
            <WarningFooter />
          </div>
        );
      case 3:
        return (
          <div className="onboarding__step">
            <div>
              <div className={`onboarding__step-logo-3 ${appTypeClassName}`} />
              <div className="onboarding__step-count">3/3</div>
              <div className="onboarding__step-info-2">{formatMessage('For registered users only')}</div>
            </div>

            <div className="onboarding__step__bottom">
              <Button className="onboarding__exit themed-background" onClick={onNotNowCallback}>
                {formatMessage('Start registration')}
              </Button>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const notFirstView = getStorageItem(NOT_FIRST_VIEW_STORAGE_KEY);

  return (
    <div className={`onboarding ${appTypeClassName}`}>
      {!notFirstView && !REGIONS_WITH_ONBOARDING.some((i) => i === APP_TYPE) ? null : getOnboardingSteps()}
    </div>
  );
};
