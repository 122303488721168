import { APP_TYPES } from '../../const/appConstants';

const themesByAppTypes = {
  [APP_TYPES.IRELAND]: [
    {
      id: 1,
      color: '#320171',
    },
    {
      id: 2,
      color: '#DCC19A',
    },
    {
      id: 3,
      color: '#2355A2',
    },
    {
      id: 4,
      color: '#FFD400',
    },
    {
      id: 5,
      color: '#393943',
    },
  ],
  [APP_TYPES.TAIWAN]: [
    {
      id: 1,
      color: '#320171',
    },
    {
      id: 2,
      color: '#DCC19A',
    },
    {
      id: 3,
      color: '#2355A2',
    },
    {
      id: 4,
      color: '#FFD400',
    },
    {
      id: 5,
      color: '#393943',
    },
  ],
  [APP_TYPES.HUNGARY]: [
    {
      id: 1,
      color: '#0C59AE',
    },
    {
      id: 2,
      color: '#FEDD00',
    },
    {
      id: 3,
      color: '#C8102E',
    },
  ],
  [APP_TYPES.GEORGIA]: [
    {
      id: 1,
      color: '#0C59AE',
    },
    {
      id: 2,
      color: '#FEDD00',
    },
    {
      id: 3,
      color: '#C8102E',
    },
  ],
  [APP_TYPES.ARMENIA]: [
    {
      id: 1,
      color: '#0C59AE',
    },
    {
      id: 2,
      color: '#FEDD00',
    },
    {
      id: 3,
      color: '#C8102E',
    },
    {
      id: 5,
      color: '#168eca',
    },
  ],
  [APP_TYPES.AZERBAIJAN]: [
    {
      id: 1,
      color: '#0C59AE',
    },
    {
      id: 2,
      color: '#FEDD00',
    },
    {
      id: 3,
      color: '#C8102E',
    },
  ],
  [APP_TYPES.BELARUS]: [
    {
      id: 1,
      color: '#FEDD00',
    },
    {
      id: 2,
      color: '#0C59AE',
    },
    {
      id: 3,
      color: '#0C59AE',
    },
    {
      id: 4,
      color: '#252525',
    },
  ],
  [APP_TYPES.DEMO]: [
    {
      id: 1,
      color: '#320171',
    },
    {
      id: 2,
      color: '#DCC19A',
    },
    {
      id: 3,
      color: '#2355A2',
    },
    {
      id: 4,
      color: '#FFD400',
    },
    {
      id: 5,
      color: '#393943',
    },
    {
      id: 10,
      color: '#000000',
    },
    {
      id: 11,
      color: '#000000',
    },
    {
      id: 12,
      color: '#000000',
    },
    {
      id: 13,
      color: '#000000',
    },
    {
      id: 14,
      color: '#000000',
    },
  ],
  [APP_TYPES.TABATERRA_AZ]: [
    {
      id: 1,
      color: '#320171',
    },
    {
      id: 2,
      color: '#DCC19A',
    },
    {
      id: 3,
      color: '#2355A2',
    },
    {
      id: 4,
      color: '#FFD400',
    },
    {
      id: 5,
      color: '#393943',
    },
  ],
};

export const brands = themesByAppTypes[process.env.REACT_APP_APP_TYPE];
