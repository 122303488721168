import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  selectedProviderId: null,
  providers: [],
  selectedProductId: null,
  products: [],
  prizes: [],
  transfers: [],
  isTransferPending: false,
  isTutorialPassed: localStorage.getItem('transferToMobileNotFirstView'),
  isFetching: false,
  epulTransfers: null,
  errorMessage: '',
  currentCategoryId: null,
};

const bonusShopSlice = createSlice({
  name: 'bonusShop',
  initialState,
  extraReducers: {
    '@@INIT': (payload) => {
      return {
        ...initialState,
        ...payload,
      };
    },
  },
  reducers: {
    getCategories() {},
    setCategories(state, action) {
      const { categories } = action.payload;
      return {
        ...state,
        categories,
      };
    },
    chooseCategory(state, category) {
      return {
        ...state,
        currentCategoryId: category.payload.selectedCategoryId,
      };
    },
    getProviders(state, action) {
      return {
        ...state,
        products: [],
        selectedProductId: null,
      };
    },
    setProviders(state, action) {
      const { providers } = action.payload;
      const isProvidersAlreadyInState = state.providers
        ? state.providers.filter((provider) => provider.category_id === providers[0].category_id)
        : [];
      if (isProvidersAlreadyInState.length > 0 && isProvidersAlreadyInState.length === providers.length) {
        return {
          ...state,
          selectedProviderId: providers[0] ? providers[0].id : null,
        };
      } else {
        const newProviders = state.providers
          ? state.providers.filter((provider) => provider.category_id !== providers[0].category_id).concat(providers)
          : providers;

        return {
          ...state,
          providers: newProviders,
          selectedProviderId: providers[0] ? providers[0].id : null,
        };
      }
    },
    setSelectedProviderId(state, action) {
      const { selectedProviderId } = action.payload;
      let productId = state.selectedProductId;
      if (state.products.length > 0) {
        const product = state.products.find((i) => i.provider_id === selectedProviderId);
        if (product) {
          productId = product.id;
        } else {
          productId = null;
        }
      }

      return {
        ...state,
        selectedProviderId,
        selectedProductId: productId,
      };
    },
    getProducts(selectedProviderId) {},
    setProducts(state, action) {
      const { products } = action.payload;
      if (products) {
        return {
          ...state,
          products,
          selectedProductId: products.length > 0 ? products[0].id : null,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    addProducts(state, action) {
      const { products } = action.payload;
      if (products) {
        const newProduct = [...products, ...state.products];
        return {
          ...state,
          products: newProduct,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    chooseProduct(productId) {},
    setSelectedProductId(state, action) {
      const { selectedProductId } = action.payload;
      return {
        ...state,
        selectedProductId,
      };
    },
    makeOrder(payload) {},
    makeTransfer(payload) {},
    makeCardTransfer(payload) {},
    saveCardNumber(payload) {},
    setTransferPending(state, action) {
      return {
        ...state,
        isTransferPending: action.payload,
      };
    },
    getPrizes() {},
    setPrizes(state, action) {
      state.prizes = action.payload.prizes;
    },
    getTransfers() {},
    setTransfers(state, action) {
      const { transfers } = action.payload;
      return {
        ...state,
        transfers,
      };
    },
    closeTutorial(state) {
      localStorage.setItem('transferToMobileNotFirstView', true);
      return {
        ...state,
        isTutorialPassed: true,
      };
    },
    setTutorialPassed(state, action) {
      return {
        ...state,
        isTutorialPassed: action.payload,
      };
    },
    setOppaWallet() {},
    requestOppaSms(state, { payload }) {
      return { ...state, currentCoinsReserved: payload.currentCoinsReserved };
    },
    makeOppaTransfer() {},
    launchTimerOppaButton(state, { payload }) {
      return { ...state, launchTimer: payload.launchTimer };
    },
    setUmicoWallet() {},
    makeUmicoTransfer() {},
    launchTimerUmicoButton(state, { payload }) {
      return { ...state, launchTimer: payload.launchTimer };
    },
    disabledUmicoTimerButton(state, { payload }) {
      return { ...state, disabledButton: payload.disabledButton };
    },
    getAddress() {},
    setAddress(state, action) {
      const { address } = action.payload;
      return {
        ...state,
        ...address,
      };
    },
    setPerxUserInfo() {},
    makePerxTransfer() {},
    getPerxCardInvoice() {},
    getPerxSegmentationCoefficient() {},
    setPerxSegmentationCoefficient(state, { payload }) {
      return { ...state, coefficient: payload.coefficient };
    },
    launchTimerPerxButton(state, { payload }) {
      return { ...state, launchTimer: payload.launchTimer };
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setUnicardWallet() {},
    makeUnicardTransfer() {},
    requestUnicardSms(state, { payload }) {
      return { ...state, currentCoinsReserved: payload.currentCoinsReserved };
    },
    getUnicardUserAccounts() {},
    setUnicardUserAccounts(state, { payload }) {
      return { ...state, cardAccounts: payload };
    },
    setUnicardUserAccount(state, { payload }) {
      return { ...state, unicardAccount: payload };
    },
    getUnicardTransfers() {},
    setUnicardTransfers(state, { payload }) {
      const { transfers } = payload;

      return {
        ...state,
        unicardTransfers: transfers,
      };
    },
    launchTimerUnicardButton(state, { payload }) {
      return { ...state, launchTimer: payload.launchTimer };
    },
    viewed() {},
    isUsed(state, { payload }) {},
    checkEpulNumber() {},
    resendEpulSms() {},
    getEpulTransfers() {},
    sendEpulSms() {},
    setEpulTransfers(state, { payload }) {
      state.epulTransfers = payload;
    },
    makeEpulTransfer(state, { payload }) {},
    setErrorMessage(state, { payload }) {
      state.errorMessage = payload;
    },
  },
});

export const bonusShopActions = bonusShopSlice.actions;

export default bonusShopSlice.reducer;
