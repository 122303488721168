import React, { memo, useEffect, useRef } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { useInternationalization } from '../../hooks/useTranslationHook';

import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { getUnverificationInfoForBelarus } from '../../redux/verification/verificationSelectors';
import { getPrizesSelector } from '../../redux/bonusShop/bonusShopSelectors';
import { formatDate } from '../../helpers';
import { analyticHelper } from '../../helpers/analyticHelper';
import { isBelarus } from '../../helpers';
import { DownloadIcon } from '../../icons/download-icon';
import { DownloadIconBelarus } from '../../icons/DownloadIconBelarus';

import styles from './Prizes.module.scss';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';

type Prize = {
  id: number;
  categoryPhoto: string;
  providerName: string;
  categoryName: string;
  createdAt: string;
  price: number | null;
  nominal_price: number | null;
  pdfLink: string;
  isUsed: boolean;
  viewed: boolean;
};

export const Prizes = memo(() => {
  const { formatMessage } = useInternationalization();
  const prizesRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const prizes = useSelector(getPrizesSelector);
  const isUnverified = useSelector(getUnverificationInfoForBelarus);
  const location = useLocation();

  useEffect(() => {
    dispatch(bonusShopActions.getPrizes({}));
    analyticHelper.prizesOpen();
  }, [dispatch]);

  useEffect(() => {
    const swappableViews = document.getElementById('swipable-views');
    if (swappableViews && prizesRef.current && location.pathname === '/prizes') {
      amplitudeHelper.openPrizes();
      swappableViews.style.height = `${prizesRef.current?.clientHeight}px`;
    }
  }, [location.pathname]);

  const renderContent = () => {
    if (isUnverified) {
      return (
        <div className={styles.empty}>
          <p className={styles.description}>{formatMessage('Prizes are unavailable')}</p>
        </div>
      );
    } else {
      if (!isEmpty(prizes)) {
        return prizes.map(renderPrize);
      }
      return <div className={styles.empty}>{formatMessage('List is empty')}</div>;
    }
  };

  const renderPrize = (prize: Prize) => {
    const currency = '€';
    const coefficient = 100;
    const {
      id,
      categoryPhoto,
      providerName,
      categoryName,
      createdAt,
      price,
      nominal_price,
      pdfLink,
      isUsed,
      viewed,
    } = prize;
    const onCheckedClick = (isUsed: boolean, id: number) => {
      dispatch(bonusShopActions.isUsed({ is_used: isUsed, reward_id: id }));
    };

    const onDownloadClick = (id: number) => {
      window.open(pdfLink);
      dispatch(bonusShopActions.viewed({ reward_id: id }));
    };

    return (
      <div key={id} className={classnames(styles.blockWrapper, { [styles.border]: isBelarus() })}>
        <div className={styles.prize}>
          <img src={categoryPhoto} className={isBelarus() ? styles.photoBelarus : styles.photo} alt="photo" />
          <div className={styles.prizeInfo}>
            <span
              className={
                isBelarus() ? classnames(styles.titleBelarus, { prizesUnused: !viewed && pdfLink }) : styles.title
              }
            >
              {providerName}
            </span>
            <p className={styles.price}>
              {categoryName} {price ? ` • ${currency}` + (nominal_price || price / coefficient).toFixed(0) : ''}
            </p>
            <p className={styles.price}>{formatDate(new Date(createdAt), 'dd MMMM yyyy')}</p>
            {pdfLink && isBelarus() && (
              <div className={styles.usedWrapper}>
                <input
                  checked={isUsed}
                  type="checkbox"
                  id={`checkbox${id}`}
                  name="check"
                  className={classnames(styles.checkbox, 'prizes-page__checkbox')}
                  onChange={() => onCheckedClick(!isUsed, id)}
                />
                <label htmlFor={`checkbox${id}`} className={classnames(styles.label, 'prizes-page__label')} />
                <span className={styles.text}>Использован</span>
                {!viewed && <span className={classnames(styles.markerNew, 'prizes-page__markerNew')} />}
              </div>
            )}
          </div>
        </div>
        {pdfLink && (
          <button type="submit" className={styles.downloadButton} onClick={() => onDownloadClick(id)}>
            {isBelarus() ? <DownloadIconBelarus /> : <DownloadIcon />}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={styles.prizeContainer} ref={prizesRef}>
      {renderContent()}
    </div>
  );
});
