import { useDispatch } from 'react-redux';
import { change, reset } from 'redux-form';
import { createStepListContainer } from '../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { EnterPhonePageContainer } from './enterPhonePage/enterPhonePageContainer';
import { VerifyContainer } from './verifyContainer/verifyContainer';
import { SignUpPage } from './signupPage/index';
import { OnboardingPage } from './onboardingPage/onboardingPage';
import { EsignPage } from './esignPage/esignPage';
import { SignUpSecondStage } from './signupPage/signUpSecondStage/index';
import { CheckDataPage } from './checkDataPage/checkDataPage';
import { authActions } from '../../redux/auth/authSlice';
import { isBelarus } from '../../helpers';
import progressBarImageStep3 from '../../images/Frame1.png';
import progressBarImageStep5 from '../../images/Frame3.png';
import { SigningPage } from './signupPage/signing';
import TermsAcceptance from '../../components/common/TermsAcceptance/TermsAcceptance';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import {
  dataRetrievalButtonText,
  personalDataButtonText,
  textForDataRetrieval,
  textForPersonalData,
} from './authPageConstants';
import { APP_TYPES } from '../../const/appConstants';
import './authPage.scss';

export const AuthPage = (props) => {
  const dispatch = useDispatch();

  const StepList = createStepListContainer(STEP_LIST_IDS.authStepList, [
    {
      id: 0,
      Component: () => <OnboardingPage />,
    },
    {
      id: 1,
      Component: () => <EnterPhonePageContainer />,
    },
    {
      id: 2,
      Component: () => <VerifyContainer />,
    },
    {
      id: 3,
      Component: () => <SignUpPage />,
    },
    {
      id: 4,
      Component: () => <EsignPage />,
    },
    {
      id: 5,
      Component: () => <SignUpSecondStage />,
    },
    {
      id: 6,
      Component: () => <CheckDataPage />,
    },
  ]);

  const onSigningDone = (link, signupForm) => {
    dispatch(change('signupForm', 'signature', link));
    if (!isBelarus()) {
      dispatch(authActions.sendSignature(link));
    } else {
      dispatch(authActions.signup({ signupForm, link }));
    }
  };

  const goBackCallback = () => {
    dispatch(
      stepListActions.prevStep({
        stepListId: STEP_LIST_IDS.authStepList,
      }),
    );
  };

  const onArrowButtonClickPersonalData = () => {
    dispatch(
      stepListActions.nextStep({
        stepListId: STEP_LIST_IDS.authStepList,
      }),
    );
    dispatch(reset('termsAcceptanceForm'));
  };

  const onArrowButtonClickDataRetrieval = () => {
    dispatch(reset('termsAcceptanceForm'));
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 6,
      }),
    );
  };

  const StepListForBelarus = createStepListContainer(STEP_LIST_IDS.authStepList, [
    {
      id: 0,
      Component: () => <OnboardingPage />,
    },
    {
      id: 1,
      Component: () => <EnterPhonePageContainer />,
    },
    {
      id: 2,
      Component: () => <VerifyContainer />,
    },
    {
      id: 3,
      Component: () => (
        <TermsAcceptance
          onBackArrowClick={goBackCallback}
          buttonText={personalDataButtonText}
          text={textForPersonalData}
          progressBarImage={progressBarImageStep3}
          onArrowButtonClick={onArrowButtonClickPersonalData}
        />
      ),
    },
    {
      id: 4,
      Component: () => <SignUpPage />,
    },
    {
      id: 5,
      Component: () => (
        <TermsAcceptance
          onBackArrowClick={goBackCallback}
          text={textForDataRetrieval}
          buttonText={dataRetrievalButtonText}
          progressBarImage={progressBarImageStep5}
          onArrowButtonClick={onArrowButtonClickDataRetrieval}
        />
      ),
    },
    {
      id: 6,
      Component: () => <SigningPage onDoneClick={onSigningDone} goBackCallback={goBackCallback} />,
    },
  ]);

  const StepLists = () => {
    switch (process.env.REACT_APP_APP_TYPE) {
      case APP_TYPES.BELARUS: {
        return <StepListForBelarus />;
      }
      default: {
        return <StepList />;
      }
    }
  };

  if (props.location.search && props.location.search.includes('?token=')) {
    dispatch(authActions.authByToken(props.location.search.substring(props.location.search.indexOf('?token=') + 7)));
  }
  return (
    <div className="auth-page">
      <StepLists />
    </div>
  );
};
