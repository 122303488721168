import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { baChatActions, baChatSelectors, chatConstants, chatActions } from '../../../../redux/chat';

import { MessageBlock, NavigationWrapper, SendMessageBlock } from '../../components';
import { LoaderContainer, Spinner } from '../../../../components/common';

import styles from '../../views/chat/Chat.module.scss';

export const BAChat = () => {
  const dispatch = useDispatch();

  const messagesByDate = useSelector(baChatSelectors.selectMessagesByDate);
  const hasMore = useSelector(baChatSelectors.selectHasMore);
  const isFetching = useSelector(baChatSelectors.selectIsFetching);
  const isImageUploading = useSelector(baChatSelectors.selectIsImageUploading);
  const totalCount = useSelector(baChatSelectors.selectTotal);
  const isInitialLoading = isFetching && !totalCount;

  const fetchMessages = (offset: number = 0) => {
    dispatch(baChatActions.fetchMessages({ offset }));
  };

  const fetchMore = () => {
    fetchMessages(totalCount);
  };

  const sendMessage = (text: string) => {
    dispatch(baChatActions.sendMessage({ text }));
  };

  const uploadImage = (image: object) => {
    dispatch(baChatActions.uploadImage({ image }));
  };

  const reset = () => {
    dispatch(baChatActions.reset());
    dispatch(chatActions.fetchUnreadMessagesCount());
  };

  useEffect(() => {
    fetchMessages();

    return reset;
  }, []);

  return (
    <NavigationWrapper isChat>
      <>
        <SendMessageBlock onSendMessageClick={sendMessage} onImageUpload={uploadImage} />
        <div id={chatConstants.SCROLL_CONTAINER_ID} className={styles.chat}>
          <LoaderContainer isLoading={isInitialLoading || isImageUploading} className={styles.loaderContainer}>
            <InfiniteScroll
              style={{ display: 'flex', flexDirection: 'column-reverse' }}
              scrollableTarget={chatConstants.SCROLL_CONTAINER_ID}
              inverse
              initialScrollY={2000}
              dataLength={totalCount}
              hasMore={hasMore}
              next={fetchMore}
              loader={<Spinner width={100} />}
            >
              {messagesByDate.map(([date, messages]) => (
                <MessageBlock key={date} date={date} messages={messages} />
              ))}
            </InfiniteScroll>
          </LoaderContainer>
        </div>
      </>
    </NavigationWrapper>
  );
};
