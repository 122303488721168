import axios from 'axios';

import { API_URL } from './baseApi';
import { APP_TYPES } from '../const/appConstants';
import packageObj from '../../package.json';
import { isIosDevice } from '../helpers/isIosDeviceHelper';

export const authApi = {
  sendPhone: async (phone) => {
    return await axios.post(`${API_URL}/v3/auth/phone`, {
      phone,
    });
  },
  auth: async (phone, code) => {
    const response = await axios.post(`${API_URL}/v3/auth/code`, {
      phone,
      code,
    });

    return response;
  },
  signup: async (data) => {
    const apiTypes = {
      [APP_TYPES.IRELAND]: 'v3',
      [APP_TYPES.HUNGARY]: 'v6',
      [APP_TYPES.ARMENIA]: 'v11',
      [APP_TYPES.GEORGIA]: 'v11',
      [APP_TYPES.AZERBAIJAN]: 'v11',
      [APP_TYPES.BELARUS]: 'v8',
      [APP_TYPES.DEMO]: 'v3',
    };
    const apiType = apiTypes[process.env.REACT_APP_APP_TYPE];
    const response = await axios.post(`${API_URL}/${apiType}/auth/register`, { ...data });

    return response;
  },

  foundMistake: async () => {
    const response = await axios.post(`${API_URL}/v8/auth/mistake`);

    return response;
  },

  sendSignature: async (signature) => {
    const response = await axios.post(`${API_URL}/v8/auth/sign`, { signature });

    return response;
  },

  removeAccount: async () => {
    return await axios.post(`${API_URL}/v3/profile/remove`);
  },

  token: async (firebaseToken) => {
    const response = await axios.post(`${API_URL}/v3/push/token`, {
      identifier: firebaseToken,
    });

    return response;
  },
  verifyCode: async (traffic_code) => {
    const response = await axios.get(`${API_URL}/v3/auth/validation?traffic_code=${traffic_code}`, { traffic_code });

    return response;
  },
  esign: async (email) => {
    const response = await axios.post(`${API_URL}/v3/auth/esign`, { email });

    return response;
  },
  esignLogin: async (envelopeId) => {
    const response = await axios.post(`${API_URL}/v3/auth/esign-login`, { envelopeId });

    return response;
  },
  validateAccountCode: async (params) => {
    let query = '';

    for (const [key, value] of Object.entries(params)) {
      if (value) {
        query += `${key}=${value}&`;
      }
    }

    return await axios.get(`${API_URL}/v11/auth/validation?${query}`);
  },
  validateSignUpForm: async (params) => {
    const apiTypes = {
      [APP_TYPES.IRELAND]: 'v6',
      [APP_TYPES.HUNGARY]: 'v6',
      [APP_TYPES.ARMENIA]: 'v6',
      [APP_TYPES.GEORGIA]: 'v6',
      [APP_TYPES.AZERBAIJAN]: 'v6',
      [APP_TYPES.BELARUS]: 'v8',
      [APP_TYPES.DEMO]: 'v6',
    };
    const apiType = apiTypes[process.env.REACT_APP_APP_TYPE];
    let query = '';

    for (const [key, value] of Object.entries(params)) {
      if (value) {
        query += `${key}=${value}&`;
      }
    }
    return await axios.get(`${API_URL}/${apiType}/auth/validation?${query}`);
  },
  getOutletsInfo: async () => {
    return await axios.get(`${API_URL}/v11/auth/addresses`);
  },
};

export const initAuthorization = () => {
  const token = localStorage.getItem('authToken');
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common.version = packageObj.version;
    axios.defaults.headers.common.platform = `PWA ${isIosDevice ? 'IOS' : 'Android'}`;
  }
};
