import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { chatActions, supportChatActions, supportChatSelectors } from '../../../../redux/chat';
import { Chat } from '../../views';

export const SupportChat = () => {
  const dispatch = useDispatch();

  const messagesByDate = useSelector(supportChatSelectors.selectMessagesByDate);
  const hasMore = useSelector(supportChatSelectors.selectHasMore);
  const isFetching = useSelector(supportChatSelectors.selectIsFetching);
  const isImageUploading = useSelector(supportChatSelectors.selectIsImageUploading);
  const totalCount = useSelector(supportChatSelectors.selectTotal);
  const isInitialLoading = isFetching && !totalCount;

  const isIreland = process.env.REACT_APP_APP_TYPE === 'IRELAND';

  const fetchMessages = (offset: number = 0) => {
    dispatch(supportChatActions.fetchMessages({ offset }));
  };

  const fetchMore = () => {
    fetchMessages(totalCount);
  };

  const sendMessage = (text: string) => {
    dispatch(supportChatActions.sendMessage({ text }));
  };

  const uploadImage = (formData: object) => {
    dispatch(supportChatActions.uploadImage({ formData }));
  };

  const reset = () => {
    dispatch(supportChatActions.reset());
    dispatch(chatActions.fetchUnreadMessagesCount());
  };

  useEffect(() => {
    fetchMessages();

    return reset;
  }, []);

  return (
    <Chat
      headerTitle={isIreland ? 'Support' : ''}
      messagesByDate={messagesByDate}
      totalCount={totalCount}
      hasMore={hasMore}
      fetchMore={fetchMore}
      isLoading={isInitialLoading || isImageUploading}
      onSendMessageClick={sendMessage}
      onImageUpload={uploadImage}
    />
  );
};
