export const DownloadIconBelarus = ({ ...props }) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8058 8.70312L8 13.5089L3.19421 8.70312L4.07812 7.81921L7.375 11.1161V0H8.625V11.1161L11.9219 7.81921L12.8058 8.70312ZM16 14.75H0V16H16V14.75Z"
        fill="#B2B2B2"
      />
    </svg>
  );
};
