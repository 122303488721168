import React, { MouseEventHandler } from 'react';
import { VoucherTransactionWithDetails } from '../../types/Voucher';

import styles from './MyVouchers.module.scss';

interface VouchersListItemProps {
  redeemedVoucher: VoucherTransactionWithDetails;
  onClick: MouseEventHandler;
}

const MyVouchersListItem = ({ onClick, redeemedVoucher }: VouchersListItemProps) => {
  return (
    <div className={styles.list_item}>
      <img src={redeemedVoucher.voucher?.image} onClick={onClick} />
      <div className={styles.title}>{redeemedVoucher.voucher?.name}</div>
    </div>
  );
};

export default MyVouchersListItem;
