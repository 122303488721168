export const SECTION_TYPES = {
  BOX: 'BOX',
  ITEM: 'ITEM',
  MODULE: 'MODULE',
  FOCUS: 'FOCUS',
  FADING_BLOCK: 'FADING_BLOCK',
};

export const COMPONENT_TYPES = {
  SOLO_QUIZ: 'SOLO_QUIZ',
  QUIZ_WITH_USER: 'QUIZ_WITH_USER',
  QUESTION_OF_THE_DAY: 'QUESTION_OF_THE_DAY',
  KB_ARTICLE: 'KB_ARTICLE',
  SPECIAL_QUIZ: 'SPECIAL_QUIZ',
  KB_GAME: 'KB_GAME',
  QUEST: 'QUEST',
  LOTTERY: 'LOTTERY',
  BONUS_SHOP: 'BONUS_SHOP',
  GENERAL_QUIZ: 'GENERAL_QUIZ',
  PROMO_CODE: 'PROMO_CODE',
  SINGLE_MODE: 'SINGLE_MODE',
  EVENT_MODE: 'EVENT_MODE',
  SURVEY: 'SURVEY',
  PACK_RECOGNITION: 'PACK_RECOGNITION',
  KB_CATEGORY: 'KB_CATEGORY',
  MULTIBRAND_QUIZ: 'MULTIBRAND_QUIZ',
  LOYALTY_PROGRAM: 'LOYALTY_PROGRAM',
  CHECK_IN: 'CHECK_IN',
  INVITE_CODE: 'INVITE_CODE',
  SALES_TIPS: 'SALES_TIPS',
  ACHIEVEMENTS: 'ACHIEVEMENTS',
  ACHIEVEMENT: 'ACHIEVEMENT',
  LEP: 'LEP',
  DAILY_GAME: 'DAILY_GAME',
  QUICK_TIP: 'QUICK_TIP',
  KB: 'KB',
  MYSTERY_SHOPPER: 'MYSTERY_SHOPPER',
};

export const KB_SECTION_TYPES = {
  KNOWLEDGE_BANK: 1,
  BRANDING_BAN: 2,
};
