import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { checkIsIphoneX } from '../../helpers/checkIsIphoneX';
import { analyticHelper } from '../../helpers/analyticHelper';
import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { getSelectedProvider } from '../../redux/bonusShop/bonusShopSelectors';
import { modalActions } from '../containers/Modal/modalSlice';

import { BonusShopProductInfo } from '../bonusShopProductInfo';
import { Button } from '../common/Button';

import './providersCarousel.scss';

const SLIDES_PER_VIEW = 1.5;

export const PhysicalProvidersCarousel = ({ providers, currentProviderIndex = 0 }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const selectedProvider = useSelector(getSelectedProvider);

  const [currentIndex, updateCurrentIndex] = useState(currentProviderIndex);
  const [disableOrder, setDisableOrder] = useState(0);
  const [selectedColor, setSelectedColor] = useState();

  const handleSlideChange = (swiper) => {
    updateCurrentIndex(swiper.activeIndex);
  };

  const showModalCallback = () => {
    dispatch(
      modalActions.openPromptModal({
        btnText: formatMessage('OK(choose color)'),
        title: formatMessage("Choose the gift's color"),
        description: formatMessage('Choose the color and tap for completing your order.'),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  useEffect(() => {
    const selectedProviderId = providers[currentIndex] && providers[currentIndex].id;
    dispatch(bonusShopActions.setSelectedProviderId({ selectedProviderId }));
    setDisableOrder(false);
    setSelectedColor(null);
  }, [dispatch, providers, currentIndex]);

  const order = () => {
    setDisableOrder(true);
    if (!selectedColor && selectedProvider.colors.length > 0) {
      showModalCallback();
      setDisableOrder(false);
      return;
    }
    dispatch(bonusShopActions.makeOrder({ productId: selectedProvider.id, product_color_id: selectedColor }));
    analyticHelper.bonusOrder();
  };

  const handleOrderBtnClick = () => {
    dispatch(
      modalActions.openPromptModal({
        btnText: 'Order(Confirm)',
        title: formatMessage('Are you sure you want to order a product?'),
        description: "Please note that after confirmation you won't be able to cancel the order.",
        image: <img className="modal-item-image" src={selectedProvider.photo} alt="" />,
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          order();
        },
      }),
    );
  };

  const getProductsByColor = (color) => {
    const backgroundColor = color.color;
    if (color.id === selectedColor) {
      return (
        <div key={color.id} className="products__product-physical-container">
          <div
            className={classnames('products__product-color', 'selected-physical')}
            style={{ backgroundColor: `#${backgroundColor}` }}
          />
          <div className="products__product-color-name">{color.name}</div>
        </div>
      );
    } else {
      return (
        <div key={color.id} className="products__product-physical-container" onClick={() => setSelectedColor(color.id)}>
          <div
            className={classnames('products__product-color', selectedColor && 'not-selected')}
            style={{ backgroundColor: `#${backgroundColor}` }}
          />
          <div className="products__product-color-name">{color.name}</div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="carousel-block">
        <Swiper slidesPerView={SLIDES_PER_VIEW} onSlideChange={handleSlideChange} spaceBetween={-10}>
          {providers.map((provider) => (
            <SwiperSlide
              className={classnames('carousel-block--swiper-container', {
                'carousel-last-item': currentIndex === providers.length - 1,
              })}
            >
              <img className="item-image" src={provider.photo} alt={provider.name} key={provider.id} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="products">
          {selectedProvider && selectedProvider.colors && selectedProvider.colors.length > 0 ? (
            <div className="products-container">
              {selectedProvider.colors.map((product) => getProductsByColor(product))}
            </div>
          ) : (
            <div className="products-container-no-products-info" />
          )}
        </div>

        <BonusShopProductInfo
          description={selectedProvider?.description}
          price={selectedProvider?.price}
          promo_price={selectedProvider?.promo_price}
          product_name={selectedProvider?.name}
          discount_price_data={selectedProvider?.discount_price_data}
        />
      </div>
      <div
        className="product-info-block__button-container"
        style={{ marginBottom: checkIsIphoneX() ? '60px' : '30px' }}
      >
        <Button
          disabled={disableOrder}
          customClass={`product-info-block__button${disableOrder ? '-disabled' : ''}`}
          onClick={() => handleOrderBtnClick()}
        >
          {formatMessage('Order')}
        </Button>
      </div>
    </>
  );
};
