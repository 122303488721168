import { format, intlFormat } from 'date-fns';
import { enGB, hu, ka, hy, az, ru, zhTW } from 'date-fns/locale';

import { APP_TYPE, APP_TYPES } from '../const/appConstants';

const locales = {
  [APP_TYPES.IRELAND]: enGB,
  [APP_TYPES.TAIWAN]: zhTW,
  [APP_TYPES.HUNGARY]: hu,
  [APP_TYPES.GEORGIA]: ka,
  [APP_TYPES.ARMENIA]: hy,
  [APP_TYPES.AZERBAIJAN]: az,
  [APP_TYPES.TABATERRA_AZ]: az,
  [APP_TYPES.BELARUS]: ru,
  [APP_TYPES.DEMO]: enGB,
};

export const formatDate = (date, dateFormat) => {
  return format(date, dateFormat, {
    locale: locales[APP_TYPE],
  });
};

export const formatTime = (date, dateFormat) => {
  return intlFormat(date, dateFormat, {
    locale: locales[APP_TYPE],
  });
};
