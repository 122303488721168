import _ from 'lodash';

import { CONFIG_KEYS, isFeatureEnabled } from '../config';
import { APP_TYPE, APP_TYPES } from '../const/appConstants';
import en from './en.json';
import hu from './hu.json';
import ka from './ka.json';
import hy from './hy.json';
import az from './az.json';
import legacyAz from './legacy/az.json';
import demo from './demo.json';
import ru from './ru.json';
import tw from './tw.json';

const TRANSLATION_KEYS = {
  [APP_TYPES.TAIWAN]: tw,
  [APP_TYPES.TABATERRA_AZ]: az,
  [APP_TYPES.IRELAND]: en,
  [APP_TYPES.HUNGARY]: hu,
  [APP_TYPES.GEORGIA]: ka,
  [APP_TYPES.ARMENIA]: hy,
  [APP_TYPES.AZERBAIJAN]: legacyAz,
  [APP_TYPES.BELARUS]: ru,
  [APP_TYPES.DEMO]: demo,
};

export const APP_TRANSLATIONS = TRANSLATION_KEYS[APP_TYPE];

export const handleI18Error = (e) => console.log(`I18n error: ${e.message}`);

if (isFeatureEnabled(CONFIG_KEYS.BILINGUAL_ENABLED)) {
  const missedTranslations = [];

  _.map(APP_TRANSLATIONS, (_value, key) => {
    APP_TRANSLATIONS[key] = `${APP_TRANSLATIONS[key]} ${en[key]}`;

    if (!en[key]) {
      missedTranslations.push(key);
    }
  });

  if (missedTranslations.length) {
    console.warn(`Missing EN translation for keys: '${JSON.stringify(missedTranslations, null, 1)}'`);
  }
}
