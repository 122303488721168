import { useSpring, animated } from 'react-spring';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import './IosAddToHomescreenBaner.scss';

export const IosAddToHomescreenBaner = () => {
  const { formatMessage } = useInternationalization();
  const appearenceAnimation = useSpring({
    bottom: 0,
    from: { bottom: -200 },
    config: { mass: 1, tension: 500, friction: 30 },
  });

  return (
    <animated.div style={appearenceAnimation} className="ios-add-to-homescreen">
      <div className="ios-add-to-homescreen__baner">
        <div className="ios-add-to-homescreen__title">{formatMessage('install.iphone_connex')}</div>
        <div className="ios-add-to-homescreen__description">
          {formatMessage('tap')}
          <div className="ios-add-to-homescreen__ios-icon"></div>
          {formatMessage('install.add_to_screen')}
        </div>
      </div>
    </animated.div>
  );
};
