import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace, goBack } from 'connected-react-router';
import { useHistory } from 'react-router';

import {
  getSelectedArticleSelector,
  getCurrentGameProgressSelector,
} from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { getUserIdSelector } from '../../redux/auth/authSelectors';
import { getUserInfoSelector } from '../../redux/profile/profileSelectors';
import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { analyticHelper } from '../../helpers/analyticHelper';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';

import { Routes } from '../../const';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { BackArrowButton } from '../../components/common/BackArrowButton/BackArrowButton';
import { FAQ_ARTICLE_ID } from '../profile/progress/progress';
import { API_URL } from '../../api/baseApi';
import { CONFIG_KEYS, isFeatureEnabled } from '../../config';

import './articlePage.scss';

export const ArticlePage = ({ location }) => {
  const dispatch = useDispatch();
  const iframeRef = useRef(null);
  const history = useHistory();
  const [iframeMounted, setIframeMounted] = useState(false);
  const [articleUrl, setArticleUrl] = useState(null);
  const userId = useSelector(getUserIdSelector);
  const userInfo = useSelector(getUserInfoSelector);
  const article = useSelector(getSelectedArticleSelector);
  const currentGameProgress = useSelector(getCurrentGameProgressSelector);

  useEffect(() => {
    if (article?.id && article?.url) {
      const url = new URL(article.url);
      const { searchParams } = url;

      const authToken = localStorage.getItem('authToken');

      searchParams.set('api_url', `${API_URL}/v3/knowledge-bank/game-progress`);
      searchParams.set('article_id', article.id);
      searchParams.set('token', authToken);
      article.game_progress && searchParams.set('data', article.game_progress);

      setArticleUrl(url.href);
    }
  }, [article, userInfo, userId]);

  useEffect(() => {
    const listener = (event) => {
      if (event.data && event.data.data && event.data.message === 'saveData') {
        if (currentGameProgress && currentGameProgress[userId] && currentGameProgress[userId][article.id]) {
          if (
            JSON.parse(event.data.data).completedCount <
              JSON.parse(currentGameProgress[userId][article.id].progress).completedCount ||
            (JSON.parse(event.data.data).completedLevels &&
              JSON.parse(currentGameProgress[userId][article.id].progress).completedLevels &&
              JSON.parse(event.data.data).completedLevels.length <
                JSON.parse(currentGameProgress[userId][article.id].progress).completedLevels.length)
          ) {
            console.log('ERROR');
            setArticleUrl(null);
          }
        }
        let newProgress;
        if (currentGameProgress && currentGameProgress[userId]) {
          newProgress = {
            ...currentGameProgress,
            [userId]: {
              ...currentGameProgress[userId],
              [article.id]: { progress: event.data.data },
            },
          };
        } else {
          newProgress = {
            ...currentGameProgress,
            [userId]: {
              [article.id]: { progress: event.data.data },
            },
          };
        }
        if (location && location.search && location.search.includes('?data=')) {
          console.log('ERROR');
        } else {
          dispatch(knowledgeBankActions.setGameProgress({ gameProgress: newProgress }));
        }
      }

      if (event.data && event.data.message === 'rewardUser') {
        dispatch(
          knowledgeBankActions.readArticle({
            articleId: article.id,
            articleType: null,
            is_sales_tip: false,
            type: event.data.type,
            withProgress: true,
          }),
        );
      }

      if (event.data === 'loseGame' && article && article.id) {
        dispatch(
          knowledgeBankActions.loseGame({
            articleId: article.id,
          }),
        );
      }
      if (event.data === 'readArticle' && article.id === FAQ_ARTICLE_ID) {
        dispatch(goBack());
        return;
      }
      if (
        event.data &&
        event.data.message === 'readArticleWithType' &&
        (event.data.articleType !== undefined || event.data.type !== undefined) &&
        article &&
        article.id
      ) {
        const type = event.data.articleType || event.data.type;
        dispatch(
          knowledgeBankActions.readArticle({
            articleId: article.id,
            articleType: type,
            isSalesTip: article.isSalesTip,
          }),
        );
      }
      if (event.data === 'readArticle' && article && article.id) {
        dispatch(knowledgeBankActions.readArticle({ articleId: article.id, isSalesTip: article.isSalesTip }));
        amplitudeHelper.articleFinish();
        analyticHelper.readArticle();
      }
      if (event.data === 'isMounted') {
        setIframeMounted(true);
      }
      if (event.data === 'closeWebView') {
        if (article.category_id) {
          return dispatch(replace(`${Routes.KnowledgeBankRoute}/${article.category_id}`));
        } else {
          return dispatch(goBack());
        }
      }
    };

    if (window.addEventListener) {
      window.addEventListener('message', listener);
    } else {
      window.attachEvent('onmessage', listener);
    }

    return () => {
      if (window.removeEventListener) {
        window.removeEventListener('message', listener);
      } else {
        window.detachEvent('onmessage', listener);
      }
    };
  }, [dispatch, article, userId, currentGameProgress, location]);

  useEffect(() => {
    if (iframeRef && iframeMounted && article) {
      if (article.minutes_to_read) {
        iframeRef.current.contentWindow.postMessage(
          {
            message: 'startTimer',
            minutes_to_read: article.minutes_to_read,
          },
          '*',
        );
      }

      if (article.retail_price) {
        iframeRef.current.contentWindow.postMessage(
          {
            message: 'setRetailPrice',
            retail_price: article.retail_price,
          },
          '*',
        );
      }
    }
  }, [iframeMounted, article]);

  useEffect(() => {
    if (isFeatureEnabled(CONFIG_KEYS.INTERACTIVE_ARTICLE_ENABLED)) {
      if (article.id !== FAQ_ARTICLE_ID) {
        dispatch(knowledgeBankActions.getInteractiveArticle(article.id));
      }
    }
  }, []);

  const isImageRegExp = /\.(gif|jpg|jpeg|tiff|png)$/i;

  const goToKnowledgeBank = () => {
    amplitudeHelper.articleExit();
    if (article && article.category_id) {
      history.push(`${Routes.KnowledgeBankRoute}/${article.category_id}`);
    } else {
      history.goBack();
    }
  };

  const articleDisplayElement = article.url.match(isImageRegExp) ? (
    <img src={articleUrl} alt="Article" style={{ width: '100%' }} />
  ) : (
    <iframe
      ref={iframeRef}
      title="Article"
      className="article-page__content"
      frameBorder="no"
      height={window.innerHeight}
      width={window.innerWidth}
      src={articleUrl}
    />
  );

  return (
    <ScrollBlock fromTop>
      <BackArrowButton
        withoutTheme
        customClass={article.id === FAQ_ARTICLE_ID ? 'article-page__back-button_black' : 'back-arrow-button'}
        onHistoryReplaceCallback={goToKnowledgeBank}
      />
      {articleUrl && articleDisplayElement}
    </ScrollBlock>
  );
};
