import { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty-typed';

import cssConstants from '../../styles/consts.scss';
import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { BonusShopCategoryCard, ChanceCard } from '../../components';
import { analyticHelper } from '../../helpers/analyticHelper';
import { Routes } from '../../const';
import { APP_TYPES } from '../../const/appConstants';
import { getIsFetching } from '../../redux/bonusShop/bonusShopSelectors';
import { ContentSpinner } from '../../components/common/Spinner/ContentSpinner';
import { amplitudeBonusShopResolver, amplitudeHelper } from '../../helpers/ampitudeHelper';
import { idCardActions } from '../../redux/idCard/idCardSlice';
import { isAzerbaijan, isGeorgia, isIreland } from '../../helpers';
import { idCardSelectors } from '../../redux/idCard/idCardSelectors';
import { ID_CARD_STATUS } from '../../redux/idCard/idCardConstants';
import { ChanceCardIreland } from '../chancePage/ChanceCardIreland/ChanceCardIreland';
import { chanceActions } from '../../redux/chance/chanceSlice';
import { selectChances } from '../../redux/chance/chanceSelectors';
import { CONFIG_KEYS, isFeatureEnabled } from '../../config';
import BonusShopStub from './BonusShopStub/BonusShopStub';
import './bonusShop.scss';

const { headerHeight, spinnerHeight } = cssConstants;

export const BonusShop = memo(({ history, location, activePage }) => {
  const dispatch = useDispatch();
  const bonusRef = useRef(null);

  const categories = useSelector((state) => state.bonusShop.categories);
  const isFetching = useSelector(getIsFetching);
  const idCardData = useSelector(idCardSelectors.selectIdCardData);
  const chances = useSelector(selectChances);
  const chooseCategory = ({ selectedCategoryId }) => {
    dispatch(bonusShopActions.chooseCategory({ selectedCategoryId }));
  };

  useEffect(() => {
    dispatch(idCardActions.getIdCard());
    dispatch(chanceActions.getChance());
  }, []);

  const onChanceCardClick = () => {
    if (chances.length > 1) {
      history.push(Routes.ChanceList);
    } else {
      const chanceId = chances[0].id;
      history.push(`${Routes.ChanceRoute}/${chanceId}`);
    }
  };

  const bonusShopNotAllowed =
    idCardData?.status === ID_CARD_STATUS.NO_DATA ||
    idCardData?.status === ID_CARD_STATUS.DUPLICATE_CARD ||
    idCardData?.status === ID_CARD_STATUS.INCORRECT_DATA ||
    idCardData?.status === ID_CARD_STATUS.NOT_CITIZEN ||
    idCardData?.status === ID_CARD_STATUS.WRONG_IMAGE ||
    idCardData?.status === ID_CARD_STATUS.OTHER_REASON ||
    isEmpty(idCardData);

  const idCardOnModeration = idCardData?.status === ID_CARD_STATUS.MODERATION;

  if (!isFeatureEnabled(CONFIG_KEYS.BONUS_SHOP_ENABLED)) {
    return <BonusShopStub />;
  }

  return isFetching ? (
    <ContentSpinner style={{ top: `calc(50% - ${spinnerHeight} / 2 - ${headerHeight})` }} />
  ) : (
    <div ref={bonusRef}>
      {!isEmpty(chances) && <ChanceCard />}
      {!isEmpty(chances) && isIreland() && <ChanceCardIreland onClick={onChanceCardClick} />}
      <div className="bonus-shop-page__categories">
        {categories.map((category) => (
          <BonusShopCategoryCard
            key={category.id}
            category={category}
            onChoose={() => {
              if ((isAzerbaijan() || isGeorgia()) && bonusShopNotAllowed) {
                history.push(Routes.IdCard);
              } else if (idCardOnModeration) {
                history.push(Routes.IdCardResult);
              } else {
                if (category.type === 3) {
                  if (process.env.REACT_APP_APP_TYPE === APP_TYPES.AZERBAIJAN) {
                    history.replace(Routes.MoneyForThePhone);
                    return;
                  } else if (process.env.REACT_APP_APP_TYPE === APP_TYPES.ARMENIA) {
                    history.replace(Routes.MoneyForTheWallet);
                    return;
                  }
                }
                if (category.type === 4) {
                  if (process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) {
                    history.replace(Routes.Boosters);
                    return;
                  }
                }
                if (category.type === 11) {
                  if (process.env.REACT_APP_APP_TYPE === APP_TYPES.TABATERRA_AZ) {
                    history.replace(Routes.M10MainRoute);
                    return;
                  }
                }
                if (category.type === 6) {
                  if (process.env.REACT_APP_APP_TYPE === APP_TYPES.GEORGIA) {
                    history.replace(Routes.OPPAMainRoute);
                    return;
                  }
                }
                if (category.id === '1000') {
                  if (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND) {
                    history.replace(Routes.PERX);
                    amplitudeHelper.bonusPerx();
                    return;
                  }
                }

                if (category.type === 8) {
                  if (process.env.REACT_APP_APP_TYPE === APP_TYPES.GEORGIA) {
                    history.replace(Routes.Unicard);
                    return;
                  }
                }
                if (category.type === 9 && isAzerbaijan()) {
                  history.replace(Routes.Epul);
                  return;
                }
                chooseCategory({ selectedCategoryId: category.id });
                amplitudeBonusShopResolver.hasOwnProperty(category.id) && amplitudeBonusShopResolver[category.id]();
                analyticHelper.bonusCategorySelect(category.id);
              }
            }}
          />
        ))}
        {isFeatureEnabled(CONFIG_KEYS.CASHOUT_DEMO_ENABLED) && (
          <BonusShopCategoryCard
            key={'cashout_demo'}
            category={{ name: 'Cashout', photo: require('../Cashout/CashoutDemo/cashout-icon.jpg') }}
            onChoose={() => history.push(Routes.CashoutDemo)}
          />
        )}
      </div>
    </div>
  );
});
