// @ts-nocheck
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import styles from './VoucherDetails.module.scss';
import { bonusShopApi } from '../../api/bonusShopApi';
import { VoucherTransactionWithDetails } from '../../types/Voucher';
import { Header } from '../../components/common/Header';
import { LoadingPage } from '../../components/common/Spinner/LoadingPage';
import { Button } from '../../components/common/Button';
import { Balance } from '../../components/common/Balance';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { NavigationMenu } from '../../components';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { isVoucherExpired, isVoucherRedeemed } from '../../helpers/edenred';

export const EDENRED_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';

const VoucherDetails = ({ match }) => {
  const { formatMessage } = useInternationalization();

  const transactionId = match.params.id;

  const [details, setDetails] = useState<VoucherTransactionWithDetails>(null);
  const [isRedemptionStatusUpdated, setIsRedemptionStatusUpdated] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const details: IEdenredOrderStatusDto = await bonusShopApi.getVoucherDetails(transactionId);
      setDetails(details);
    })();
  }, []);

  if (!details) {
    return <LoadingPage />;
  }

  if (!details.order) {
    console.warn(`Order details is missing for transaction: ${transactionId}`);
    return null;
  }

  const isRedeemed = isVoucherRedeemed(details);
  const isExpired = isVoucherExpired(details);

  const updateVoucherStatus = async () => {
    try {
      setIsRedemptionStatusUpdated(true);
      await bonusShopApi.updateVoucherRedemptionStatus(transactionId, !isRedeemed);
    } catch (error) {
      setIsRedemptionStatusUpdated(false);
      console.warn(error);
    }
  };

  const handleVoucherOpen = () => {
    window.open(details.order?.ShortUrl, '_blank');
  };

  return (
    <div className={styles.root}>
      <div className="header">
        <Header hasBackButton>
          <p className="bonus-shop-category-page__header-text">{formatMessage('my_vouchers.title')}</p>
        </Header>
        <Balance />
      </div>
      <div className="test"></div>
      <div className="content">
        <img src={details.voucher.image} />

        <div className="title">{details.voucher.name}</div>
        <hr />
        <div className="description">{details.voucher.description}</div>
        <div className="info">
          {formatMessage('my_vouchers.passcode')}&nbsp;
          <span className="passcode">{details.order.ShortUrlAuthCode}</span>
        </div>
        <div className="info">
          {formatMessage('my_vouchers.expiration')}&nbsp;
          {moment(details.order.ExpirationDate, EDENRED_DATE_FORMAT).format('DD-MM-YYYY HH:mm')}
        </div>
        <div className="info">
          {formatMessage('my_vouchers.voucher_number')}&nbsp;{details.order.VoucherNo}
        </div>

        <Button customClass={styles.button} onClick={handleVoucherOpen}>
          {formatMessage('my_vouchers.open')}
        </Button>
        <br />
        <br />
        {!isExpired && (
          <Button customClass={styles.button} onClick={updateVoucherStatus} disabled={isRedemptionStatusUpdated}>
            {formatMessage(isRedeemed ? 'my_vouchers.mark_as_active' : 'my_vouchers.mark_as_used')}
          </Button>
        )}
      </div>
      <NavigationMenu location={location} />
      <DisclaimerFooter />
    </div>
  );
};

export default VoucherDetails;
