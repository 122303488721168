import { createSlice } from '@reduxjs/toolkit';

const initialState = { userId: null, token: null, signUpData: null, isButtonDisabled: false, subscription: null };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSubscription: (state, action) => ({
      ...state,
      subscription: action.payload,
    }),
    setAuthData(state, action) {
      const { token, userId } = action.payload;
      return {
        ...state,
        token,
        userId,
      };
    },
    logout() {
      return {
        ...initialState,
      };
    },
    removeAccount() {},
    foundMistake() {},
    sendSignature(link) {},
    signupSuccess() {},
    auth(phone, code) {},
    authByToken(token) {},
    sendPhone(phone) {},
    esign(email) {},
    esignLogin(envelopeId) {},
    validateAccountCode(payload) {},
    setSignupData(state, action) {
      return {
        ...state,
        signUpData: action.payload,
      };
    },
    disableButton(state, action) {
      return {
        ...state,
        isButtonDisabled: action.payload,
      };
    },
    signup() {},
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
