import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';

import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { authActions } from '../../../redux/auth/authSlice';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { isFeatureEnabled, CONFIG_KEYS } from '../../../config';

import '../profileSettings.scss';

export const ProfileSettingsBottomArea = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.profile.userInfo);

  useEffect(() => {
    analyticHelper.editProfileOpen();
  }, []);

  const shareCode = () => {
    copy(userInfo.invite_code);
    analyticHelper.profileEditInviteCode();
  };

  const handleLogout = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Do you want to log out?',
        description: 'Tap “Yes” to continue',
        btnText: 'Yes',
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(authActions.logout());
        },
      }),
    );
  };

  const removeAccount = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Are you sure?',
        description: 'Removing your account is irrevocable. You can no longer recover your data',
        btnText: 'Proceed',
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(authActions.removeAccount());
        },
      }),
    );
  };

  return (
    <div className="profile-settings-page__content-invite-block">
      {isFeatureEnabled(CONFIG_KEYS.INVITE_CODE_ENABLED) && (
        <>
          <p className="profile-settings-page__content-invite-block-caption">{formatMessage('Invite code')}</p>
          <p className={classnames('profile-settings-page__content-invite-block-caption', 'text')}>
            {formatMessage('Share this invite code with your colleagues to get extra points.')}
          </p>
          <div className="profile-settings-page__content-invite-block-code" onClick={shareCode}>
            <p className="profile-settings-page__content-invite-block-code-text">{userInfo.invite_code}</p>
          </div>
        </>
      )}
      <div className="logout-delete-account-block">
        <p className="logout-delete-account-block-caption" onClick={handleLogout}>
          {formatMessage('Log out')}
        </p>
        <p className="logout-delete-account-block-caption" onClick={removeAccount}>
          {formatMessage('Remove account')}
        </p>
      </div>
    </div>
  );
};
