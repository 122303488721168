import './Button.scss';

import classnames from 'classnames';

export const Button = ({ children, customClass, disabled = false, withoutTheme = false, ...otherProps }) => {
  return (
    <button
      disabled={disabled}
      className={classnames('btn-component', customClass, {
        themed: !withoutTheme,
        'btn-disabled': disabled,
      })}
      {...otherProps}
    >
      {children}
    </button>
  );
};
