import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router';

import { bonusShopApi } from '../../api/bonusShopApi';
import { VoucherTransactionWithDetails } from '../../types/Voucher';
import { Routes } from '../../const';
import VouchersListItem from './MyVouchersListItem';
import VouchersByCategory from '../../components/VouchersByCategory';
import { isFeatureEnabled, CONFIG_KEYS } from '../../config';

interface VouchersProps {
  isActive?: boolean;
}

const MyVouchers = ({ isActive }: VouchersProps) => {
  const { push } = useHistory();
  const [vouchers, setVouchers] = useState<VoucherTransactionWithDetails[]>([]);

  useEffect(() => {
    if (!isActive) {
      return;
    }
    const fetchDataAsync = async () => {
      const result = await bonusShopApi.getVouchersList();
      if (JSON.stringify(result) !== JSON.stringify(vouchers)) {
        setVouchers(result);
      }
    };

    fetchDataAsync();
  }, [isActive]);

  const handleDetailsClick = useCallback(
    (transactionId: number) => push(`${Routes.VoucherDetails}/${transactionId}`),
    [],
  );

  if (isFeatureEnabled(CONFIG_KEYS.VOUCHERS_SPLIT_BY_CATEGORY_ENABLED)) {
    return <VouchersByCategory vouchers={vouchers} handleDetailsClick={handleDetailsClick} />;
  }

  return (
    <>
      {vouchers.map((voucher) => (
        <VouchersListItem redeemedVoucher={voucher} onClick={() => handleDetailsClick(voucher.id)} />
      ))}
    </>
  );
};

export default MyVouchers;
