import { EDENRED_VOUCHER_UPDATE_STATUS } from '../const/edenredConstants';
import { VoucherTransactionWithDetails } from '../types/Voucher';

export const isVoucherExpired = ({ metadata }: VoucherTransactionWithDetails) => {
  const today = new Date().toISOString();

  const { EXPIRATION_DATE } = metadata;

  return EXPIRATION_DATE < today;
};

export const isVoucherRedeemed = ({ metadata }: VoucherTransactionWithDetails) => {
  const { EDENRED_VOUCHER_STATUS } = metadata;

  return EDENRED_VOUCHER_STATUS === EDENRED_VOUCHER_UPDATE_STATUS.REDEMPTION;
};
